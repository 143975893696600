const ApiUrl = process.env.REACT_APP_API_URL;

const UrlParamsReplace = (url, params = {}) => {
  let urlWithPrefix = `${ApiUrl}${url}`;
  if (params) {
    Object.keys(params).forEach(
      (key) => (urlWithPrefix = urlWithPrefix.replace(`:${key}`, params[key]))
    );
  }
  return urlWithPrefix;
};

export const SYSTEM_ADMIN_LOGIN_URL = () => UrlParamsReplace("/auth/login");
export const GET_USER_BY_ID = (id) =>
  UrlParamsReplace("/auth/get-user/:id", { id });

export const GET_ALL_DATA = (size, page, search) =>
  UrlParamsReplace(
    "/customers/get-all-cutomers?size=:size&page=:page&search=:search",
    {
      size,
      page,
      search,
    }
  );
export const GET_CUSTOMER_BY_ID = (zc_po_id) =>
  UrlParamsReplace("/room/get-cutomer/:zc_po_id", { zc_po_id });

export const ADD_CUSTOMER_FV_DATA = () =>
  UrlParamsReplace("/customers/add-update-customer");

export const UPDATE_CUSTOMER_FV_DATA = (customerId) =>
  UrlParamsReplace("/customers/update-fv-data/:customerId", { customerId });

export const UPLOAD_IMAGE = (customerId) => UrlParamsReplace("/upload-file");

export const DOWNLOAD_PDF = (customerId) =>
  UrlParamsReplace(`/customers/generate-pdf/${customerId}`);

export const DOWNLOAD_IMAGES_PDF = (customerId) =>
  UrlParamsReplace(`/customers/generate-images-pdf/${customerId}`);

export const GET_FLOOR_PLAN_BY_ZC_PO_ID = (zc_po_id) =>
  UrlParamsReplace(`/customers/get-floor-plan/:zc_po_id`, { zc_po_id });

// Push fv data to zoho api
export const PUSH_FVDATA_TO_ZOHO = () =>
  UrlParamsReplace(`/customers/push-to-zoho`);

export const CLONE_ROOM = () => UrlParamsReplace(`/room/clone-room`);
export const MAKE_SUBROOM = () => UrlParamsReplace(`/room/make-sub-room`);
export const ADD_NEW_ROOM = () => UrlParamsReplace(`/room/add-new-room`);
export const EDIT_ROOM = () => UrlParamsReplace(`/room/update-room`);
export const DELETE_ROOM = () => UrlParamsReplace(`/room/delete-room`);
export const RESTORE_ROOM = () => UrlParamsReplace(`/room/restore-room`);

// api.js

import { setCustomerData } from "../redux/customer";
import { getCustomerById } from "../services/customers.service";
import { getAuth } from "../services/identity.service";

export const fetchCustomerData = async (dispatch, searchParams) => {
  const auth = getAuth();
  const queryValue = searchParams.get("id");

  try {
    // setLoading(true);
    // if (queryValue) {
    const customerRes = await getCustomerById(queryValue, auth?.token);
    const customer = customerRes?.data?.entity;
    if (customerRes?.data?.status) {
      dispatch(setCustomerData(customer));
    }
    // }
    // setLoading(false);
  } catch (error) {
    // Handle error
    console.error("Error fetching customer data:", error);
  }
};

import React from "react";
import { motion } from "framer-motion";
import { fetchCustomerData } from "../../utils/api";
import { restoreRoom } from "../../services/customers.service";
import { setLoading } from "../../redux/customer";
import { useDispatch, useSelector } from "react-redux";
import { Constants } from "../../utils/Constants";
import { useSearchParams } from "react-router-dom";
import { Tooltip } from "@mui/material";

const DeletedRoomButton = ({ deletedRoom, deletedRoomArray }) => {
  const customerId = useSelector((state) => state.customerReducer.customerId);
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  const restoreRoomHandle = async (room) => {
    // restoreRoom
    dispatch(setLoading({ [Constants.SUBROOM_LOADER]: true }));
    let child =
      room?.childRooms && room?.childRooms?.length > 0
        ? [...room?.childRooms]
        : [];
    let apiValue = {
      customer_id: customerId,
      room_ids: [room?.id, ...child],
    };

    let restoreRoomRes = await restoreRoom(apiValue);
    if (restoreRoomRes?.data?.status) {
      // await roomDeleteFunc("Room deleted successfully", "success");
      await fetchCustomerData(dispatch, searchParams);
      dispatch(setLoading({ [Constants.SUBROOM_LOADER]: false }));
    } else {
      // await roomDeleteFunc("Error while deleting the rooms", "Error");
      dispatch(setLoading({ [Constants.SUBROOM_LOADER]: false }));
    }
  };
  return (
    <div className="w-[250px]">
      {!deletedRoom?.hasOwnProperty("subRoomof") && (
        <div className={`m-1 cursor-pointer`}>
          <motion.div
            className={`relative shadow-lg text-ellipsis rounded-xl py-[12px] text-[16px] text-[#1E2E5A] bg-white  cursor-pointer
            `}
            id="roomArea"
          >
            <Tooltip title="Restore">
              <motion.button
                whileHover={{ scale: 1.1 }}
                className={`absolute top-[7px] right-[3px]  p-1.5 rounded-full   shadow-md shadow-gray-400 restore-tooltip-${deletedRoom.id}`}
                data-modal-target="crud-modal"
                data-modal-toggle="crud-modal"
                data-tip="Restore"
                transition={{ duration: 0.3 }}
                id="not-clickable"
                onClick={() => restoreRoomHandle(deletedRoom)}
              >
                <img
                  src="/images/restore_icon.svg"
                  style={{ width: "20px", height: "20px" }}
                />
              </motion.button>
            </Tooltip>
            {deletedRoom?.name}-{deletedRoom?.finalTotalSqFeet || 0} SF
          </motion.div>
        </div>
      )}
      {deletedRoom?.childRooms &&
        deletedRoom?.childRooms?.length > 0 &&
        deletedRoom?.childRooms?.map((id) => {
          let findRoom = deletedRoomArray.find((room) => room?.id == id);
          if (findRoom) {
            return (
              <div className={`m-2 ml-7 `}>
                <motion.div className="border text-ellipsis border-[#1E2E5A] rounded-lg py-[8px] text-[16px] text-[#1E2E5A]">
                  <div className="flex justify-center gap-3">
                    <img
                      src="/images/arrow.png"
                      className="w-[15px] h-[17px]"
                    />
                    <span className="">
                      {findRoom?.name}-{findRoom?.finalTotalSqFeet || 0}
                    </span>
                  </div>
                </motion.div>
              </div>
            );
          } else {
            return null;
          }
        })}
    </div>
  );
};

export default DeletedRoomButton;

export const ImportantFieldQues = [
  "Can Floor be buffed & recoated?",
  "Conf. no waxes or polishes on the floor?",
  "Confirmation of enough material for sanding?",
  "Solid / Engineered ",
  "Wear Layer thickness If Eng.",
  "Details for thickness of wear layer (confirmed to thin)",
  "Excessive damage (ex. pet stains,cracked boards)",
  "Surface issues ( ex. mastic, paint, wax)",
  "Type",
  "Wear Layer of Existing Site Finished Eng. Flooring",
  "Unknown Wood Type Notes",
  "Are there any bevels",
  "Type of Bevels",
  "Species",
  "Layout",
  "Width",
  "Thickness",
  "Installation",
  "Exposed or Covered",
  "Type of flooring is covering the hardwood?",
  "Is KASA removing the existing flooring",
  "Photo of Specs",
  "Name of Staircase",
  "Scope",
  "Exposed/Covered",
  "KASA Removing?",
  "Cove Molding",
  "Species",
  "Images",
  "Length",
  "Height Diffrence",
  "To Type of Flooring",
  "COI Required for Project",
  "Floor Level of Apartment building",
  "Address",
  "Email",
];

export const furnitureImportantFields = [
  "Quantity",
  "Add Images",
  "Any water hookups",
  "Any potential clearance issues",
  "Any Gas or Water hookups",
];

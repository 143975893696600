import { createSlice } from "@reduxjs/toolkit";
import sampleJson from "../utils/sampleJson.json";
import { updateCustomerFVData } from "../services/customers.service";

const slice = createSlice({
  name: "customerList",
  initialState: {
    loading: {},
    customerList: [],
    customerId: "",
    singleCustomer: sampleJson,
    customerData: null,
    notFilledOutBtns: [],
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    getAllCustomer: (state, action) => {
      // Update the state with the row data
      state.customerList = action.payload;
    },
    singleCustomer: (state, action) => {
      state.singleCustomer = action.payload;
    },

    setCustomerId: (state, action) => {
      state.customerId = action.payload;
    },
    setNotFilledOutBtns: (state, action) => {
      state.notFilledOutBtns = action.payload;
    },
    setCustomerData: (state, action) => {
      state.customerData = action.payload;
    },
  },
});

export const {
  setLoading,
  getAllCustomer,
  singleCustomer,
  setCustomerId,
  setNotFilledOutBtns,
  setCustomerData,
  customerData,
} = slice.actions;

export const updateSingleCustomerApi =
  (fv_data) => async (dispatch, getState) => {
    let customerId = getState()?.customerReducer?.customerId;

    dispatch(singleCustomer(fv_data));
    if (customerId) {
      const fv_Updated = JSON.parse(JSON.stringify(fv_data));

      let array = fv_Updated?.isBgChangedBtnArray || [];
      if (
        fv_Updated.scope?.installation?.is_part ||
        fv_Updated?.scope?.refinishing?.is_part
      ) {
        if (!array?.includes("customerDetails"))
          fv_Updated?.isBgChangedBtnArray.push("customerDetails");
      } else {
        if (array?.includes("customerDetails")) {
          let newArray = array.filter((key) => key !== "customerDetails");

          fv_Updated["isBgChangedBtnArray"] = [...newArray];
        }
      }
      dispatch(singleCustomer(fv_Updated));
      let fvdataRes = await updateCustomerFVData(customerId, {
        fv_data: fv_Updated,
      });

      if (fvdataRes?.data?.status) {
      }
    }
  };

export default slice.reducer;

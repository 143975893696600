import React, { useEffect, useMemo, useState } from "react";
import PopUpModal from "../UI/PopUpModal";
import CloneForm from "./CloneForm";
import AddNewRoom from "./AddNewRoom";
import MuiSnackbar from "../UI/MuiSnackbar";
import SingleFloor from "./SingleFloor";
import BgLoader from "../UI/BgLoader";
import { useSelector } from "react-redux";
import { Constants } from "../../utils/Constants";
import SubmitComponent from "../SubmitComponent/SubmitComponent";
import { useSearchParams } from "react-router-dom";
import DeletedRooms from "./DeletedRooms";
import NewSubmitComponent from "../SubmitComponent/NewSubmitComponent";
import { ImportantFieldQues } from "../../utils/importantFields";
import _ from "lodash";

const ProjectFloors = ({
  customerDetails1,
  singleCustomerData,
  deletedRooms,
}) => {
  const [floors, setFloors] = useState([]);
  const [deletedRoomsArray, setDeletedRoomsArray] = useState([]);
  const [isOpenCloneModal, setIsOpenCloneModal] = useState(false);
  const [isOpenNewRoomForm, setIsOpenNewRoomForm] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [cloneRoom, setCloneRoom] = useState(null);
  const [newRoomFloor, setNewRoomFloor] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [updatedSingleCustomerData, setUpdatedSingleCustomerData] =
    React.useState({});

  const loading = useSelector((state) => state.customerReducer.loading);
  const customerId = useSelector((state) => state.customerReducer.customerId);
  const [searchParams] = useSearchParams();
  const queryValue = searchParams.get("id");

  const handleCloneClick = (roomData) => {
    if (!isOpenCloneModal) {
      setIsOpenCloneModal(!isOpenCloneModal);
      setCloneRoom(roomData);
    } else {
      if (roomData?.status) {
        setMessage("Room has been clone successfully");
        setType("success");
        setOpen("open");
      }
      setIsOpenCloneModal(!isOpenCloneModal);
    }
  };
  const handleEditClick = (roomData) => {
    if (!isOpenEditModal) {
      setIsOpenEditModal(!isOpenCloneModal);
      setCloneRoom(roomData);
    } else {
      if (roomData?.status) {
        setMessage("Room has been Edited successfully");
        setType("success");
        setOpen("open");
      }
      setIsOpenEditModal(!isOpenEditModal);
    }
  };

  const handleAddRoomBtn = (floor) => {
    if (!isOpenNewRoomForm) {
      setIsOpenNewRoomForm(!isOpenNewRoomForm);
      setNewRoomFloor(floor);
    } else {
      if (floor?.status) {
        setMessage("Room has been added successfully");
        setType("success");
        setOpen("open");
      }
      setIsOpenNewRoomForm(!isOpenNewRoomForm);
    }
  };

  const roomDeleteFunc = (message, type) => {
    setMessage(message);
    setType(type);
    setOpen("open");
  };

  useEffect(() => {
    setFloors(customerDetails1 || []);
    setDeletedRoomsArray(deletedRooms || []);
  }, [customerDetails1, deletedRooms]);

  let totalProjectSqft = 0;
  customerDetails1?.map((floor) => {
    floor.rooms?.map((room) => {
      totalProjectSqft += Number(room?.finalTotalSqFeet) || 0;
    });
  });
  totalProjectSqft = parseFloat(totalProjectSqft?.toFixed(2));

  const widthStyle = useMemo(() => {
    let width =
      floors?.length === 1
        ? "w-[300px]"
        : floors?.length === 2
        ? "w-[550px]"
        : floors?.length === 3
        ? "w-[800px]"
        : "w-[1050px]";

    return width;
  }, [floors]);

  const updatedQueWithImportantKey = React.useCallback(
    (allQues) => {
      return allQues?.map((que) => {
        let subQues = [];
        if (que?.subQuestion) {
          subQues = updatedQueWithImportantKey(que?.subQuestion); // recursive call for subQuestions if exists
        }
        if (ImportantFieldQues.includes(que?.question)) {
          return {
            ...que,
            important: true,
            mandatory: false,
            subQuestion: subQues,
          };
        } else {
          return que;
        }
      });
    },
    [singleCustomerData]
  );

  const addImportantKey = React.useCallback(
    (checklist) => {
      return checklist?.map((cl) => ({
        ...cl,
        all_questions: updatedQueWithImportantKey(cl?.all_questions),
      }));
    },
    [singleCustomerData]
  );

  const addImportantKeyInQuestion = React.useCallback(() => {
    const cloneCustomer = _.cloneDeep(singleCustomerData);
    const refCL = cloneCustomer?.refinishing_checklists;
    const extCL = cloneCustomer?.existing_materials;
    const levellingCL = cloneCustomer?.levellings;
    const matchingInsCL = cloneCustomer?.matching_installation_checklists;
    const staircaseCL = cloneCustomer?.staircases;
    const transitionCL = cloneCustomer?.transitions;
    const kitchenFurnitureCL = cloneCustomer?.kitchen_furnitures;
    const specialItemFurnitureCL = cloneCustomer?.specialItem_furnitures;
    const buildingTypeCL = cloneCustomer?.customer?.buildingType?.subQuestion;

    const updatedRefCl = addImportantKey(refCL);
    const updatedExtCl = addImportantKey(extCL);
    const updatedLevellingCl = addImportantKey(levellingCL);
    const updatedMatchingInsCL = addImportantKey(matchingInsCL);
    const updatedstaircaseCL = addImportantKey(staircaseCL);
    const updatedtransitionCL = addImportantKey(transitionCL);
    const updatedKitchenFurnitureCL = addImportantKey(kitchenFurnitureCL);
    const updatedSpecialItemFurnitureCL = addImportantKey(
      specialItemFurnitureCL
    );
    const updatedbuildingTypeCL = updatedQueWithImportantKey(buildingTypeCL);

    cloneCustomer.refinishing_checklists = updatedRefCl || [];
    cloneCustomer.existing_materials = updatedExtCl || [];
    cloneCustomer.levellings = updatedLevellingCl || [];
    cloneCustomer.matching_installation_checklists = updatedMatchingInsCL || [];
    cloneCustomer.staircases = updatedstaircaseCL || [];
    cloneCustomer.transitions = updatedtransitionCL || [];
    cloneCustomer.kitchen_furnitures = updatedKitchenFurnitureCL || [];
    cloneCustomer.specialItem_furnitures = updatedSpecialItemFurnitureCL || [];
    cloneCustomer.customer.buildingType =
      {
        ...cloneCustomer?.customer?.buildingType,
        subQuestion: updatedbuildingTypeCL,
      } || [];

    setUpdatedSingleCustomerData(cloneCustomer);
  }, [singleCustomerData]);

  useEffect(() => {
    if (singleCustomerData) addImportantKeyInQuestion();
  }, [singleCustomerData]);

  return (
    <>
      {(loading?.[Constants.SUBROOM_LOADER] ||
        loading?.[Constants.SUBMIT_LOADER]) && <BgLoader />}
      <div
        className={`${
          (loading?.[Constants.SUBROOM_LOADER] ||
            loading?.[Constants.SUBMIT_LOADER]) &&
          "blur-[2px] pointer-events-none"
        } flex`}
      >
        <div className="flex flex-col py-2 ml-[10px] mt-3 w-[70%]">
          <MuiSnackbar
            open={open}
            message={message || ""}
            type={type || ""}
            onClose={() => setOpen(false)}
            duration={5000}
          />
          <div
            className={`text-center border border-[#1E2E5A] bg-[#1E2E5A] text-white rounded-lg py-[5px] text-[20px] m-auto ${widthStyle}`}
          >
            Project - {totalProjectSqft} SF
          </div>
          <div className="flex justify-center gap-[16px] w-full mt-5">
            {floors?.map((floor, floorIndex) => {
              let totalFloorSqFeet = 0;
              floor.rooms?.map((room) => {
                totalFloorSqFeet += Number(room?.finalTotalSqFeet) || 0;
              });
              totalFloorSqFeet = totalFloorSqFeet?.toFixed(2);

              return (
                <SingleFloor
                  key={floor?.name}
                  floor={floor}
                  floorIndex={floorIndex}
                  setFloors={setFloors}
                  floors={floors}
                  totalFloorSqFeet={totalFloorSqFeet}
                  customerDetails={customerDetails1}
                  handleCloneClick={handleCloneClick}
                  handleAddRoomBtn={handleAddRoomBtn}
                  handleEditClick={handleEditClick}
                  deletedRooms={deletedRooms}
                  roomDeleteFunc={roomDeleteFunc}
                />
              );
            })}
          </div>
          {floors?.length > 0 && (
            <div className="mt-14">
              <NewSubmitComponent
                singleCustomerData={singleCustomerData}
                updatedSingleCustomerData={updatedSingleCustomerData}
                id={customerId}
                ZC_PO_ID={queryValue}
                setOpen={setOpen}
                setMessage={setMessage}
                setType={setType}
                floors={singleCustomerData?.scope?.floors || []}
                widthStyle={widthStyle}
              />
              {/* <SubmitComponent
                singleCustomerData={singleCustomerData}
                id={customerId}
                ZC_PO_ID={queryValue}
                setOpen={setOpen}
                setMessage={setMessage}
                setType={setType}
                widthStyle={widthStyle}
              /> */}
            </div>
          )}
        </div>
        <div className="absolute right-0 flex flex-col pt-2 mr-[15px] mt-3 top-0 h-[98%]">
          <DeletedRooms
            deletedRooms={deletedRoomsArray}
            floors={floors}
            roomDeleteFunc={roomDeleteFunc}
          />
        </div>
        <PopUpModal
          title="Clone Room"
          onCancel={handleCloneClick}
          isOpen={isOpenCloneModal}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          header="Room Clone"
        >
          <CloneForm handleCloneClick={handleCloneClick} roomData={cloneRoom} />
        </PopUpModal>

        <PopUpModal
          title="Edit Room"
          onCancel={handleEditClick}
          isOpen={isOpenEditModal}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          header="Edit Room"
        >
          <AddNewRoom
            handleAddRoomBtn={handleEditClick}
            roomData={cloneRoom}
            typeOfForm={"edit"}
            floorData={newRoomFloor}
          />
        </PopUpModal>
        <PopUpModal
          title="Add New Room"
          onCancel={handleAddRoomBtn}
          isOpen={isOpenNewRoomForm}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          header="Add New Room"
        >
          <AddNewRoom
            handleAddRoomBtn={handleAddRoomBtn}
            floorData={newRoomFloor}
            typeOfForm={"new"}
          />
        </PopUpModal>
      </div>
    </>
  );
};

export default ProjectFloors;

import React, { useEffect, useState } from "react";
import { getCustomerById } from "../../services/customers.service";
import { getAuth } from "../../services/identity.service";
import { useSearchParams } from "react-router-dom";
import ProjectFloors from "./ProjectFloors";
import { useDispatch, useSelector } from "react-redux";
import { setCustomerData, setCustomerId } from "../../redux/customer";
import Loader from "../UI/Loader";

const ScopeModification = () => {
  const customerDetails = useSelector(
    (state) => state.customerReducer.customerData
  );
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const auth = getAuth();
  const queryValue = searchParams.get("id");

  const getCustomer = async () => {
    setLoading(true);
    if (queryValue) {
      const customerRes = await getCustomerById(queryValue, auth?.token);
      const customer = customerRes?.data?.entity;
      if (customerRes?.data?.status) {
        dispatch(setCustomerId(customer?.id));
        dispatch(setCustomerData(customer));
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!!auth) {
      getCustomer();
    }
  }, []);

  return (
    <div>
      {!!auth ? (
        <ProjectFloors
          customerDetails1={customerDetails?.fv_data?.scope?.floors || []}
          singleCustomerData={customerDetails?.fv_data}
          deletedRooms={
            (customerDetails?.fv_data?.scope?.hasOwnProperty("deletedRooms") &&
              customerDetails?.fv_data?.scope?.deletedRooms) ||
            []
          }
        />
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default ScopeModification;

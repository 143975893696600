import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import ScopeModification from "../components/ScopeModification/ScopeModification";
import WithSecurity from "./WithSecurity";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          exact
          path="/nkxP5khyF4HoD8E8TTPejzuXUmKSy0ot"
          element={
            <WithSecurity>
              <ScopeModification />
            </WithSecurity>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;

import React from "react";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";

const PopUpModal = ({
  title,
  onSubmit,
  onCancel,
  children,
  isOpen,
  data,
  header,
}) => {
  return (
    <Modal
      title={title}
      open={isOpen}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}

      // onOk={handleOk}
      // confirmLoading={confirmLoading}
      // onCancel={handleCancel}
    >
      <div className="  flex justify-center items-center m-4">
        <div className="relative p-4 w-full max-w-md max-h-full">
          {/* <!-- Modal content --> */}
          <div className="relative bg-white rounded-lg shadow ">
            {/* <!-- Modal header --> */}
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
              <h3 className="text-lg font-semibold text-gray-900 ">{header}</h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center "
                data-modal-toggle="crud-modal"
                onClick={onCancel}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* <!-- Modal body --> */}
            {children}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PopUpModal;

import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";

const CustomCheckbox = ({ id, label, checked, onChange }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          id={id}
          checked={checked}
          onChange={onChange}
          sx={{
            "& .MuiSvgIcon-root": {
              fontSize: "20px", // Adjust the checkbox size
            },
          }}
        />
      }
      label={label}
      sx={{
        "& .MuiTypography-root": {
          fontSize: "14px", // Adjust the label font size
        },
      }}
    />
  );
};

export default CustomCheckbox;

export const Constants = {
  PADDING_TOP_OF_ROOM_AREA: "70px",
  MANDATE_BORDER_COLOR: "#ff00005e",
  MANDATE_TEXT_COLOR: "#ff0000bd",
  SUBROOM_LOADER: "subroom_loader",
  SUBMIT_LOADER: "submit_loader",
  CLONE_ROOM_LOADER: "clone_room_loader",
  ADD_ROOM_LOADER: "add_room_loader",
};

export const pdfStyle = {
  headingSize: 18,
  questionTextSize: 14,
  answerTextSize: 14,
  headingBgColor: "#D8D8D8",
  marginY: 1,
  paddingY: 1,
  marginL: 2,
  paddingL: 2,
  // paddingL: 2.5,
  imageW: 220,
  imageH: 170,
  checklistFont: "14px",
  checklistLeftFont: "text-[14px]",
  projectFloorText: "text-[8px]",
  projectFloorPadding: "py-[6px] px-[1px]",
  checkListLeftW: "w-[65%] pr-[4px]",
  checkListRightW: "w-[35%]",
  defaultTextColor: "text-[#1E2E5A]",
  redColor: "text-[#FF0000]",
  tableBodyTextSize: 12,
  liSpacing: "pt-[0px]",
  spanLineHeight: "leading-normal",
};

export const SubmitDialogConstants = {
  FONT_SIZE: 14,
  HEADING_FONT_SIZE: 18,
  CROSS_ICON_URL: "/images/CrossIcon.svg",
  CHECK_ICON_URL: "/images/CheckIcon.svg",
  SUBQUE_MARGIN_LEFT: 24,
  MARGIN_Y: 2,
  MAINQUE_MARGIN_LEFT: 24,
  GAP_WITH_CROSS_ICON: 2,
  GAP_WITH_CHECK_ICON: 3.5,
  // SUB_OF_SUBQUE_MARGIN_LEFT: 7,
};

import React from "react";
import { motion } from "framer-motion";
import { useDrag } from "react-dnd";

const SubRoomButton = ({
  room,
  floor,
  subRoomHoverNotAllow,
  handleEditClick,
}) => {
  const [{ isDragging }, drag] = useDrag({
    type: "SUBROOM",
    item: { type: "SUBROOM", room, floor },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <>
      <div
        ref={drag}
        className={`m-2 ml-7 hover:${
          subRoomHoverNotAllow ? "cursor-not-allowed" : "cursor-pointer"
        }`}
      >
        <motion.div
          drag={{ opacity: isDragging ? 0 : 1 }}
          className="border text-ellipsis border-[#1E2E5A] bg-orange-400 rounded-lg py-[8px] text-[16px] text-[#1E2E5A]"
          onDoubleClick={() => handleEditClick(room)}
        >
          <div className="flex justify-center gap-3">
            <img src="/images/arrow.png" className="w-[15px] h-[17px]" />
            <span className="">
              {room?.name}-{room?.finalTotalSqFeet || 0} SF
            </span>
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default SubRoomButton;

import React, { useRef } from "react";
import PropTypes from "prop-types";
import { motion, useAnimation } from "framer-motion";
import { useDrag, useDrop } from "react-dnd";
import { useEffect } from "react";
import SubRoomBotton from "../ScopeModification/SubRoomButton";
import { makeSubRoom } from "../../services/customers.service";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomerData } from "../../utils/api";
import { setLoading } from "../../redux/customer";
import { Constants } from "../../utils/Constants";

const SingleRoomButton = ({
  room,
  floor,
  floorIndex,
  roomIndex,
  moveRoomListItem,
  handleCloneClick,
  inSideRoomArea,
  setInSideRoomArea,
  setIsOver,
  subRoomHoverNotAllow,
  handleEditClick,
  customerDetails,
  deletedRooms,
  roomDeleteFunc,
}) => {
  const controls = useAnimation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const customerId = useSelector((state) => state.customerReducer.customerId);

  let isValidForDrag = React.useMemo(() => {
    return room?.childRooms && room?.childRooms?.length > 0 ? false : true;
  }, [room, customerDetails, floor, subRoomHoverNotAllow]);

  useEffect(() => {
    controls.start({ translateY: 0 });
  }, [controls]);

  const [{ isDragging }, dragRef] = useDrag({
    type: floor?.name?.toUpperCase(),
    item: { floorIndex, roomIndex, room },
    // canDrag: isValidForDrag,
    hover({ id: draggedId }) {
      if (draggedId !== room?.id) {
        const { index: overIndex } = floor?.rooms?.filter((r) => {
          if (r.id === room?.id) {
            return {
              index: floor?.rooms?.indexOf(room),
            };
          }
        })[0];
        moveRoomListItem(draggedId, overIndex);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver }, dropRef] = useDrop({
    accept: [floor?.name?.toUpperCase(), "SUBROOM"],
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
    hover: (item, monitor) => {
      if (item?.type !== "SUBROOM") {
        const dragIndex = item.roomIndex;
        const hoverIndex = roomIndex;
        const hoverBoundingRect = ref.current?.getBoundingClientRect();
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const hoverActualY =
          monitor.getClientOffset().y - hoverBoundingRect.top;

        // if dragging down, continue only when hover is smaller than middle Y
        if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;

        // if dragging up, continue only when hover is bigger than middle Y
        if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;

        moveRoomListItem(dragIndex, hoverIndex, floorIndex);
        item.roomIndex = hoverIndex;
      }
    },
    drop: async (item, monitor) => {
      // Handle the drop event separately
      if (
        item?.room?.id !== room?.id &&
        item?.room?.subRoomof?.parent_room_id !== room?.id
      ) {
        dispatch(setLoading({ [Constants.SUBROOM_LOADER]: true }));
        setInSideRoomArea(false);
        let apiValue = {
          customer_id: customerId,
          old_parent_room_id: item?.room?.subRoomof?.parent_room_id || null,
          parent_room_id: room?.id || null,
          child_room_id: item?.room?.id,
        };

        let addSubRoom = await makeSubRoom(apiValue);

        if (addSubRoom?.data?.status) {
          await fetchCustomerData(dispatch, searchParams);
          dispatch(setLoading({ [Constants.SUBROOM_LOADER]: false }));
        } else {
          roomDeleteFunc(
            addSubRoom?.data?.message || "Failed to drop room",
            "error"
          );
          dispatch(setLoading({ [Constants.SUBROOM_LOADER]: false }));
        }
      }
    },
  });

  useEffect(() => {
    setIsOver(isOver);
  }, [isOver]);

  const ref = useRef(null);
  const dragDropRef = dragRef(dropRef(ref));

  const handleroomDragEnter = () => {
    setInSideRoomArea(true);
  };
  const handleroomDragLeave = () => {
    setInSideRoomArea(false);
  };

  let findIsDeletedRoom = deletedRooms?.find(
    (dltRoom) => dltRoom?.id === room?.id
  );

  return (
    <>
      {!findIsDeletedRoom ? (
        <>
          {!room?.hasOwnProperty("subRoomof") && (
            <div ref={dragDropRef} className={`m-1 cursor-pointer`}>
              <motion.div
                whileHover={{ scale: 1.05 }}
                animate={{
                  opacity: isOver ? 0.3 : 1,
                }}
                whileDrag={{ scale: inSideRoomArea ? 1.1 : 1 }}
                onDoubleClick={() => handleEditClick(room)}
                onDragEnter={handleroomDragEnter}
                onDragLeave={handleroomDragLeave}
                className={`relative shadow-lg text-ellipsis rounded-xl py-[12px] text-[16px] text-[#1E2E5A] bg-white hover:${
                  isOver ? "cursor-not-allowed" : "cursor-pointer"
                }`}
                id="roomArea"
              >
                {isOver ? (
                  <div
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-[1px] border-dashed border-t-[1px] border-sky-500"
                    style={{
                      visibility: inSideRoomArea ? "visible" : "hidden",
                      zIndex: "999999",
                    }}
                  ></div>
                ) : (
                  <></>
                )}
                <motion.button
                  whileHover={{ scale: 1.15 }}
                  className="absolute top-[7px] right-[3px]  p-1.5 rounded-full   shadow-md shadow-gray-400 "
                  data-modal-target="crud-modal"
                  data-modal-toggle="crud-modal"
                  onClick={() => handleCloneClick(room)}
                  transition={{ duration: 0.3 }}
                >
                  <img
                    src="/images/clone-icon.png"
                    style={{ width: "20px", height: "20px" }}
                  />
                </motion.button>
                {room?.name}-{room?.finalTotalSqFeet || 0} SF
              </motion.div>
            </div>
          )}
          {room?.childRooms &&
            room?.childRooms?.length > 0 &&
            room?.childRooms?.map((id) => {
              let findRoom = floor?.rooms.find((room) => room?.id == id);
              if (findRoom) {
                return (
                  <SubRoomBotton
                    room={findRoom}
                    subRoomHoverNotAllow={subRoomHoverNotAllow}
                    floor={floor}
                    handleEditClick={handleEditClick}
                  />
                );
              } else {
                return null;
              }
            })}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

SingleRoomButton.propTypes = {
  room: PropTypes.object.isRequired,
};

export default SingleRoomButton;

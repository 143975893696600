import React from "react";
import { useDrop } from "react-dnd";
import { motion } from "framer-motion";
import DeletedRoomButton from "./DeletedRoomButton";
import { Constants } from "../../utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import { deleteRoom } from "../../services/customers.service";
import { fetchCustomerData } from "../../utils/api";
import { setLoading } from "../../redux/customer";
import { useSearchParams } from "react-router-dom";

const DeletedRooms = ({ floors, deletedRooms, roomDeleteFunc }) => {
  const floorNameArray = floors?.map((floor) => floor.name?.toUpperCase());
  const customerId = useSelector((state) => state.customerReducer.customerId);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [{ isOver }, dropRef] = useDrop({
    accept: floorNameArray,
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
    hover: (item, monitor) => {},

    drop: async (item, monitor) => {
      const dropResult = monitor.getDropResult();
      //   Check if the item type is "SUBROOM"
      // if (item.type === "SUBROOM" ) {
      dispatch(setLoading({ [Constants.SUBROOM_LOADER]: true }));
      let child =
        item?.room?.childRooms && item?.room?.childRooms?.length > 0
          ? [...item?.room?.childRooms]
          : [];
      let apiValue = {
        customer_id: customerId,
        room_ids: [item?.room?.id, ...child],
      };

      let deleteRoomRes = await deleteRoom(apiValue);
      if (deleteRoomRes?.data?.status) {
        // await roomDeleteFunc("Room deleted successfully", "success");
        await fetchCustomerData(dispatch, searchParams);
        dispatch(setLoading({ [Constants.SUBROOM_LOADER]: false }));
      } else {
        // await roomDeleteFunc("Error while deleting the rooms", "Error");
        dispatch(setLoading({ [Constants.SUBROOM_LOADER]: false }));
      }
      // }
    },
  });
  return (
    <>
      <div className="text-center border border-[#1E2E5A] bg-[#1E2E5A] text-white rounded-lg py-[5px] text-[20px] w-[300px] ">
        Deleted Rooms
      </div>
      <motion.div
        className={`mt-5 shadow-md shadow-gray-400 overflow-y-auto justify-start items-center flex flex-col  ${
          isOver ? "bg-[#ccc9c9] border-dashed border-2 border-sky-500" : ""
        }`}
        style={{ height: "calc(100% - 120px)" }}
        ref={dropRef}
        whileHover={{
          scale: isOver ? 1.05 : 1,
        }}
      >
        {deletedRooms?.map((dltRoom) => {
          return (
            <DeletedRoomButton
              deletedRoom={dltRoom}
              deletedRoomArray={deletedRooms}
            />
          );
        })}
        {deletedRooms?.length === 0 && (
          <div className="flex justify-center items-center h-full">
            <div className=" font-medium text-[18px] h-[50px] font-serif">
              + Drop here to delete the room
            </div>
          </div>
        )}
      </motion.div>
    </>
  );
};

export default DeletedRooms;

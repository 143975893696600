import React from "react";
import Input from "../UI/Input";
import { useReactHookForm } from "../../hooks/useReactHookForm";
import NoteField from "../UI/QuestionTypes/NoteField";
import * as yup from "yup";
import { cloneRoom, getCustomerById } from "../../services/customers.service";
import { getAuth } from "../../services/identity.service";
import { useDispatch, useSelector } from "react-redux";
import { setCustomerData, setLoading } from "../../redux/customer";
import { useSearchParams } from "react-router-dom";
import MuiSnackbar from "../UI/MuiSnackbar";
import { Constants } from "../../utils/Constants";
import { CircularProgress } from "@mui/material";

const CloneForm = ({ handleCloneClick, roomData }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.customerReducer.loading);

  const [searchParams] = useSearchParams();
  const auth = getAuth();
  const queryValue = searchParams.get("id");
  const customerId = useSelector((state) => state.customerReducer.customerId);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [type, setType] = React.useState("");

  const EventSchema = yup.object().shape({
    SF: yup
      .string()
      .required("SF is required")
      .test("notZero", "SF must be greater than 0", (value) => value !== "0"),

    room_name: yup.string().required("Room name is required"),
  });

  const defaultValues = {
    SF: "",
    LF: "",
    room_name: "Closet",
    room_note: "",
  };
  const { register, errors, watch, handleSubmit } = useReactHookForm({
    validationSchema: EventSchema,
    defaultValues: defaultValues,
    mode: "onChange",
  });

  const getCustomer = async () => {
    // setLoading(true);
    // if (queryValue) {
    const customerRes = await getCustomerById(queryValue, auth?.token);
    const customer = customerRes?.data?.entity;
    if (customerRes?.data?.status) {
      dispatch(setCustomerData(customer));
    }
    // }
    // setLoading(false);
  };

  const onSubmit = async (data) => {
    dispatch(setLoading({ [Constants.CLONE_ROOM_LOADER]: true }));

    // Validation passed, proceed with your logic
    let { SF, LF, room_name, room_note } = data;
    const apiValue = {
      customer_id: customerId,
      room_id: roomData?.id,
      SF: Number(SF),
      LF: Number(LF),
      room_name,
      room_note,
    };

    let cloneRoomResponse = await cloneRoom(apiValue);
    if (cloneRoomResponse?.data?.status) {
      handleCloneClick(cloneRoomResponse?.data);
      await getCustomer();
    } else {
      setMessage(
        cloneRoomResponse?.data?.message || "Error while cloning room"
      );
      setOpen(true);
      setType("error");
    }
    dispatch(setLoading({ [Constants.CLONE_ROOM_LOADER]: false }));
  };

  return (
    <>
      <MuiSnackbar
        open={open}
        message={message || ""}
        type={type || ""}
        onClose={() => setOpen(false)}
        duration={5000}
      />
      <form className="p-4 md:p-5" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid gap-4 mb-4 grid-cols-2">
          <div className="col-span-2">
            <Input
              register={register}
              id="room_name"
              name="room_name"
              placeholder="Room Name *"
              isValid={watch("room_name")}
              error={errors?.room_name}
            />
          </div>
          <div className="col-span-2 sm:col-span-1">
            <Input
              type="number"
              register={register}
              id="SF"
              name="SF"
              placeholder="SF *"
              isValid={watch("SF") == "0" ? false : watch("SF")}
              error={errors?.SF}
            />
          </div>
          <div className="col-span-2 sm:col-span-1">
            <Input
              type="number"
              register={register}
              id="LF"
              name="LF"
              placeholder="LF"
              isValid={true}
            />
          </div>
          <div className="col-span-2">
            <NoteField
              register={register}
              id="room_note"
              name="room_note"
              question="Notes"
              multiline={true}
              maxRows={3.8}
              minRows={3.8}
              filledOut={true}
            />
          </div>
        </div>
        <button
          type="submit"
          className={`text-white inline-flex items-center focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center active:border-none ${
            loading?.[Constants.CLONE_ROOM_LOADER]
              ? "bg-gray-200 pointer-events-none"
              : "bg-blue-700 hover:bg-blue-800"
          }`}
        >
          <svg
            className="me-1 -ms-1 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
              clip-rule="evenodd"
            ></path>
          </svg>
          {loading?.[Constants.CLONE_ROOM_LOADER] ? (
            <CircularProgress size={15} />
          ) : (
            "Clone"
          )}
        </button>
      </form>
    </>
  );
};

export default CloneForm;

import React from "react";
import { CircularProgress } from "@mui/material";

const BgLoader = ({ className }) => {
  return (
    <div className={`fixed left-[50%] top-[50%] z-[1000] ${className}`}>
      <CircularProgress size={30} />
    </div>
  );
};

export default BgLoader;

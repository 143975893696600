import React, { useCallback, useEffect, useMemo, useState } from "react";
import { motion } from "framer-motion";
import update from "immutability-helper";
import SingleRoomButton from "../UI/SingleRoomButton";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useDrop } from "react-dnd";
import { makeSubRoom } from "../../services/customers.service";
import { fetchCustomerData } from "../../utils/api";
import { setLoading } from "../../redux/customer";
import { Constants } from "../../utils/Constants";

const FloorWrapper = ({
  children,
  inSideRoomArea,
  setSubRoomHoverNotAllow,
  floorName,
  roomDeleteFunc,
}) => {
  const customerId = useSelector((state) => state.customerReducer.customerId);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [sameFloor, setSameFloor] = useState(false);

  const [{ isOver }, dropRef] = useDrop({
    accept: ["SUBROOM"],
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
    hover: (item, monitor) => {
      // Access the dragged item value

      // Change the state value when hovering
      setSameFloor(item?.floor?.name === floorName ? true : false);
    },

    drop: async (item, monitor) => {
      // Check if the item type is "SUBROOM"
      if (item.type === "SUBROOM" && !inSideRoomArea) {
        dispatch(setLoading({ [Constants.SUBROOM_LOADER]: true }));
        let apiValue = {
          customer_id: customerId,
          old_parent_room_id: item?.room?.subRoomof?.parent_room_id || null,
          parent_room_id: null,
          child_room_id: item?.room?.id,
        };

        let dragOutSideFromParent = await makeSubRoom(apiValue);
        if (dragOutSideFromParent?.data?.status) {
          await fetchCustomerData(dispatch, searchParams);
          dispatch(setLoading({ [Constants.SUBROOM_LOADER]: false }));
        } else {
          roomDeleteFunc(
            dragOutSideFromParent?.data?.message || "Failed to drop room",
            "error"
          );
          dispatch(setLoading({ [Constants.SUBROOM_LOADER]: false }));
        }
      }
    },
  });

  useEffect(() => {
    setSubRoomHoverNotAllow(isOver);
  }, [isOver]);

  return (
    <motion.div
      ref={dropRef}
      className={`py-4 px-2 ${
        isOver && sameFloor && !inSideRoomArea ? " rounded-lg" : ""
      }`}
      whileHover={{ scale: isOver && sameFloor && !inSideRoomArea ? 1.05 : 1 }}
      style={{
        boxShadow:
          isOver && sameFloor && !inSideRoomArea
            ? "0 4px 8px rgba(0, 0, 0, 0.2)"
            : "none",
      }}
    >
      {children}
    </motion.div>
  );
};

const SingleFloor = React.memo(
  ({
    floor,
    floorIndex,
    setFloors,
    totalFloorSqFeet,
    customerDetails,
    handleCloneClick,
    handleAddRoomBtn,
    handleEditClick,
    deletedRooms,
    roomDeleteFunc,
  }) => {
    const [inSideRoomArea, setInSideRoomArea] = useState(false);
    const [isOver, setIsOver] = useState(false);
    const [subRoomHoverNotAllow, setSubRoomHoverNotAllow] = useState(false);
    const customerData = useSelector(
      (state) => state.customerReducer?.customerData
    );
    const moveRoomListItem = useCallback(
      (dragIndex, hoverIndex, floorIndex) => {
        setFloors((currentFloors) => {
          const updatedFloors = update(currentFloors, {
            [floorIndex]: {
              rooms: {
                $splice: [
                  [dragIndex, 1],
                  [hoverIndex, 0, currentFloors[floorIndex].rooms[dragIndex]],
                ],
              },
            },
          });

          return updatedFloors;
        });
      },
      [setFloors]
    );

    const memoizedRoomBtn = useMemo(() => {
      return (
        <div className="flex flex-col gap-4">
          {floor?.rooms?.map((room, index) => (
            <SingleRoomButton
              key={room?.name + index}
              room={room}
              customerDetails={customerDetails}
              moveRoomListItem={moveRoomListItem}
              floor={floor}
              floorIndex={floorIndex}
              roomIndex={index}
              handleCloneClick={handleCloneClick}
              setInSideRoomArea={setInSideRoomArea}
              inSideRoomArea={inSideRoomArea}
              setIsOver={setIsOver}
              subRoomHoverNotAllow={subRoomHoverNotAllow}
              handleEditClick={handleEditClick}
              deletedRooms={deletedRooms}
              roomDeleteFunc={roomDeleteFunc}
            />
          ))}
        </div>
      );
    }, [floor, customerDetails, customerData, isOver]);

    return (
      <div
        className="flex flex-col gap-2 w-full max-w-[250px]"
        key={floorIndex}
      >
        <div className="border bg-[#009DC2] text-white border-[#009DC2] rounded-lg py-[8px] text-[16px]">
          {floor?.name}- {totalFloorSqFeet || 0} SF
        </div>
        <FloorWrapper
          inSideRoomArea={inSideRoomArea}
          setSubRoomHoverNotAllow={setSubRoomHoverNotAllow}
          floorName={floor?.name}
          roomDeleteFunc={roomDeleteFunc}
        >
          {memoizedRoomBtn}
        </FloorWrapper>
        <div className="flex flex-col gap-2">
          <motion.button
            whileTap={{ scale: 0.9 }}
            className="border text-ellipsis bg-[#1E2E5A] border-[#1E2E5A] rounded-lg py-[8px] text-[16px] text-white"
            onClick={() => handleAddRoomBtn(floor)}
          >
            Add New Room
          </motion.button>
        </div>
      </div>
    );
  }
);

export default SingleFloor;

import "./App.css";
import React from "react";
import AppRoutes from "./routes/AppRoutes";
import { DndProvider } from "react-dnd";
import { useSelector } from "react-redux";
import useIdleTimeout from "./hooks/useIdleTimeout";
import { removeAuth } from "./services/identity.service";
import { HTML5Backend } from "react-dnd-html5-backend";

const App = () => {
  const activeBtnKey = useSelector(
    (state) => state.customerReducer.activeBtnKey
  );

  // const handleIdle = () => {
  //   removeAuth();
  //   window.location.reload();
  // };

  // const { idleTimer } = useIdleTimeout({ handleIdle, idleTime: 3600 });

  return (
    <div className={`${activeBtnKey && "slide-image"} App`}>
      <DndProvider
        backend={HTML5Backend}
        options={{
          enableMouseEvents: true,
          // scrollAngleRanges: [
          //   { start: 75, end: 105 },
          //   { start: 255, end: 285 },
          // ],
        }}
      >
        <AppRoutes />
      </DndProvider>
      ̦
    </div>
  );
};

export default App;

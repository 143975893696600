import React, { useEffect } from "react";
import Input from "../UI/Input";
import { useReactHookForm } from "../../hooks/useReactHookForm";
import NoteField from "../UI/QuestionTypes/NoteField";
import * as yup from "yup";
import { addNewRoom, editRoom } from "../../services/customers.service";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/customer";
import { useSearchParams } from "react-router-dom";
import MuiSnackbar from "../UI/MuiSnackbar";
import { v4 as uuidv4 } from "uuid";
import CustomCheckbox from "../UI/CustomCheckBox";
import { fetchCustomerData } from "../../utils/api";
import { Constants } from "../../utils/Constants";
import { CircularProgress } from "@mui/material";

const AddNewRoom = ({ handleAddRoomBtn, floorData, typeOfForm, roomData }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.customerReducer.loading);
  const [searchParams] = useSearchParams();
  const customerId = useSelector((state) => state.customerReducer.customerId);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [type, setType] = React.useState("");

  const EventSchema = yup.object().shape({
    SF: yup
      .string()
      .required("SF is required")
      .test("notZero", "SF must be greater than 0", (value) => value !== "0"),

    room_name: yup.string().required("Room name is required"),
  });

  const defaultValues = {
    SF: "",
    LF: "",
    room_name: "",
    room_note: "",
    isOutOfScope: false,
  };
  const { register, errors, watch, setValue, handleSubmit } = useReactHookForm({
    validationSchema: EventSchema,
    defaultValues: defaultValues,
    mode: "onChange",
  });

  const checkListKeyArray = [
    "existing_material_id",
    "matching_installation_checklists_id",
    "matching_refinishing_checklists_id",
    "molding_id",
    "refinishing_checklists_id",
    "subfloor_detail_id",
  ];

  useEffect(() => {
    if (typeOfForm === "edit") {
      setValue("SF", roomData?.finalTotalSqFeet);
      setValue("LF", roomData?.linear_ft);
      setValue("room_name", roomData?.name);
      setValue("room_note", roomData?.note);
      const isAllKeysOutOfScope = checkListKeyArray.every((key) => {
        return roomData[key] === "OutOfScope";
      });
      setValue("isOutOfScope", isAllKeysOutOfScope);
    }
  }, [roomData]);

  const onSubmit = async (data) => {
    dispatch(setLoading({ [Constants.ADD_ROOM_LOADER]: true }));
    let { SF, LF, room_name, room_note, isOutOfScope } = data;
    let checkListValue = isOutOfScope ? "OutOfScope" : "";
    let dimesionObj = {
      id: uuidv4(),
      shape: "Rectangle",
      length: "",
      width: "",
      sqFeet: "",
      closet: false,
      scope: true,
    };

    if (typeOfForm === "new") {
      const roomObject = {
        name: room_name,
        type: room_name,
        id: uuidv4(),
        images: [],
        dimensions: [dimesionObj],
        subRooms: [],
        totalSqFeet: 0,
        finalTotalSqFeet: Number(SF),
        area: "",
        linear_ft: Number(LF),
        matching_refinishing_checklists_id: checkListValue,
        matching_installation_checklists_id: checkListValue,
        specialItem_id: "",
        refinishing_checklists_id: checkListValue,
        installation_checklist_id: "",
        subfloor_detail_id: checkListValue,
        existing_material_id: checkListValue,
        molding_id: checkListValue,
        levelling_id: [],
        matching_id: "",
        repair_ids: [],
        transition_from_ids: [],
        transition_to_ids: [],
        transition_within_ids: [],
        furniture_ids: [],
        note: room_note,
      };
      const apiValue = {
        customer_id: customerId,
        floor_name: floorData?.name,
        room_obj: roomObject,
      };
      let addNewRoomRes = await addNewRoom(apiValue);
      if (addNewRoomRes?.data?.status) {
        handleAddRoomBtn(addNewRoomRes?.data);
        fetchCustomerData(dispatch, searchParams);
      } else {
        setMessage(
          addNewRoomRes?.data?.message || "Error while addiing new room"
        );
        setOpen(true);
        setType("error");
      }
    } else if (typeOfForm === "edit") {
      const roomObject = {
        ...roomData,
        name: room_name,
        type: room_name,
        id: roomData?.id,
        finalTotalSqFeet: Number(SF),

        linear_ft: Number(LF),
        matching_refinishing_checklists_id: isOutOfScope
          ? checkListValue
          : roomData["matching_refinishing_checklists_id"],
        matching_installation_checklists_id: isOutOfScope
          ? checkListValue
          : roomData["matching_installation_checklists_id"],
        specialItem_id: "",
        refinishing_checklists_id: isOutOfScope
          ? checkListValue
          : roomData["refinishing_checklists_id"],
        installation_checklist_id: "",
        subfloor_detail_id: isOutOfScope
          ? checkListValue
          : roomData["subfloor_detail_id"],
        existing_material_id: isOutOfScope
          ? checkListValue
          : roomData["existing_material_id"],
        molding_id: isOutOfScope ? checkListValue : roomData["molding_id"],
        note: room_note,
      };
      const apiValue = {
        customer_id: customerId,
        room_obj: roomObject,
      };

      let editRoomRes = await editRoom(apiValue);
      if (editRoomRes?.data?.status) {
        handleAddRoomBtn(editRoomRes?.data);
        fetchCustomerData(dispatch, searchParams);
      } else {
        setMessage(editRoomRes?.data?.message || "Error while editing room");
        setOpen(true);
        setType("error");
      }
    }
    dispatch(setLoading({ [Constants.ADD_ROOM_LOADER]: false }));
  };

  return (
    <>
      <MuiSnackbar
        open={open}
        message={message || ""}
        type={type || ""}
        onClose={() => setOpen(false)}
        duration={5000}
      />
      <form className="p-4 md:p-5" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid gap-4 mb-4 grid-cols-2">
          <div className="col-span-2">
            <Input
              register={register}
              id="room_name"
              name="room_name"
              placeholder="Room Name *"
              isValid={watch("room_name")}
              error={errors?.room_name}
            />
          </div>
          <div className="col-span-2 sm:col-span-1">
            <Input
              type="number"
              register={register}
              id="SF"
              name="SF"
              placeholder="SF *"
              isValid={watch("SF") == "0" ? false : watch("SF")}
              error={errors?.SF}
            />
          </div>
          <div className="col-span-2 sm:col-span-1">
            <Input
              type="number"
              register={register}
              id="LF"
              name="LF"
              placeholder="LF"
              isValid={true}
            />
          </div>
          <div className="col-span-2">
            <NoteField
              register={register}
              id="room_note"
              name="room_note"
              question="Notes"
              multiline={true}
              maxRows={3.8}
              minRows={3.8}
              filledOut={true}
            />
          </div>
          <div className="col-span-2">
            <CustomCheckbox
              register={register}
              id="isOutOfScope"
              label="Out Of Scope"
              checked={watch("isOutOfScope")}
              onChange={(e) => setValue("isOutOfScope", e.target.checked)}
            />
          </div>
        </div>
        <button
          type="submit"
          className={`text-white inline-flex items-center focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center  ${
            loading?.[Constants.ADD_ROOM_LOADER]
              ? "bg-gray-200 pointer-events-none"
              : "bg-blue-700 hover:bg-blue-800"
          }`}
        >
          {typeOfForm === "edit" ? (
            <svg
              width="16px"
              height="16px"
              viewBox="0 0 20 20"
              version="1.1"
              fill="#ffffff"
              className="mr-2"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <title>edit_fill [#1480]</title>{" "}
                <desc>Created with Sketch.</desc> <defs> </defs>{" "}
                <g
                  id="Page-1"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  {" "}
                  <g
                    id="Dribbble-Light-Preview"
                    transform="translate(-59.000000, -400.000000)"
                    fill="#ffffff"
                  >
                    {" "}
                    <g id="icons" transform="translate(56.000000, 160.000000)">
                      {" "}
                      <path
                        d="M3,260 L24,260 L24,258.010742 L3,258.010742 L3,260 Z M13.3341,254.032226 L9.3,254.032226 L9.3,249.950269 L19.63095,240 L24,244.115775 L13.3341,254.032226 Z"
                        id="edit_fill-[#1480]"
                      >
                        {" "}
                      </path>{" "}
                    </g>{" "}
                  </g>{" "}
                </g>{" "}
              </g>
            </svg>
          ) : (
            <svg
              className="me-1 -ms-1 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                clip-rule="evenodd"
              ></path>
            </svg>
          )}
          {loading?.[Constants.ADD_ROOM_LOADER] ? (
            <CircularProgress size={15} />
          ) : typeOfForm === "edit" ? (
            "Edit"
          ) : (
            "Add"
          )}
        </button>
      </form>
    </>
  );
};

export default AddNewRoom;
